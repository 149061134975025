import {  createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';


import { entityContentRouteRef } from './routes';

export const githubListRepositoryTeamsPlugin = createPlugin({
  id: 'github-list-repository-teams',
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const EntityGitHubTeamsCard = githubListRepositoryTeamsPlugin.provide(
  createRoutableExtension({
    name: 'EntityGitHubTeamsCard',
    component: () =>
      import('./components/EntityGitHubTeamsComponent').then(m => m.EntityGitHubTeamsComponent),
    mountPoint: entityContentRouteRef,
  }),
);
