import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  Table,
  TableColumn,
  Progress,
  CodeSnippet,
  InfoCard,
} from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { Octokit } from '@octokit/rest';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { GitHubTeamsTableProps } from '../../types/GitHubTeamsTableProps.type';
import { isGithubSlugSet } from '../../utils/isGithubSlugSet';
import { Entity } from '@backstage/catalog-model';

const useStyles = makeStyles(theme => ({
  infoCard: { marginBottom: '10px' },
  code: {
    borderRadius: 6,
    margin: `${theme.spacing(2)}px 0px`,
    background: theme.palette.type === 'dark' ? '#444' : '#fff',
  },
}));

export const GitHubTeamsTable = ({ teams }: GitHubTeamsTableProps) => {
  const columns: TableColumn[] = [
    { title: 'Name', field: 'name' },
    { title: 'Permission', field: 'permission' },
  ];

  const data = teams.map(team => {
    return {
      id: team.slug,
      name: (
        <a href={team.html_url} target="_blank">
          {team.name} <OpenInNewIcon fontSize="small" />
        </a>
      ),
      permission: team.permission,
    };
  });

  return (
    <Table
      title="Collaborators and teams"
      options={{ search: false, paging: false }}
      columns={columns}
      data={data}
    />
  );
};

export function EntityGitHubTeamsNoGitHubSlug() {
  const classes = useStyles();
  const ENTITY_YAML = `metadata:
    name: example
    annotations:
      github.com/project-slug: org-name/repo-name`;

  return (
    <InfoCard title="Collaborators and teams" className={classes.infoCard}>
      <Typography variant="body1">
        No github project-slug defined for this entity. You can add a github
        project-slug to your entity YAML as shown in the highlighted example
        below:
      </Typography>
      <div className={classes.code}>
        <CodeSnippet
          text={ENTITY_YAML}
          language="yaml"
          showLineNumbers
          highlightedNumbers={[3, 4]}
          customStyle={{ background: 'inherit', fontSize: '115%' }}
        />
      </div>
    </InfoCard>
  );
}

export const EntityGitHubTeamsComponent = () => {
  const classes = useStyles();

  const { entity } = useEntity();
  const projectName = isGithubSlugSet(entity);
  const [owner, repo] = (projectName ?? '/').split('/');

  const auth = useApi(githubAuthApiRef);

  const { value, loading, error } = useAsync(async (): Promise<any[]> => {
    const token = await auth.getAccessToken(['repo']);
    const octokitOptions = { auth: token };

    const client = new Octokit(octokitOptions);
    const response = await client.repos.listTeams({
      owner,
      repo,
    });
    return response.data;
  }, []);

  if (loading) {
    return <Progress className={classes.infoCard} />;
  } else if (!projectName) {
    return <EntityGitHubTeamsNoGitHubSlug />;
  } else if (error) {
    return (
      <Alert className={classes.infoCard} severity="error">
        Collaborators and teams: {error.message}
      </Alert>
    );
  }

  return (
    <Grid className={classes.infoCard}>
      <GitHubTeamsTable teams={value || []} />
    </Grid>
  );
};

export const isPluginApplicableToEntity = (entity: Entity) =>
  Boolean(
    entity.metadata.annotations?.['backstage.io/managed-by-location'].match(
      /github\.com/,
    ) ||
      entity.metadata.annotations?.[
        'backstage.io/managed-by-origin-location'
      ].match(/github\.com/),
  );
