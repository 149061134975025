/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export const PAGERDUTY_INTEGRATION_KEY = 'pagerduty.com/integration-key';
export const PAGERDUTY_SERVICE_ID = 'pagerduty.com/service-id';

// this is a hack to allow multiple PagerDuty integration cards
export const PAGERDUTY_INTEGRATION_KEYS = [
    'pagerduty.com/integration-key',
    'pagerduty.com/secondary-integration-key',
    'pagerduty.com/third-integration-key',
    'pagerduty.com/fourth-integration-key',
];
